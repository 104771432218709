import { useCallback, useMemo } from "react";

export default function useUrlFilters<F extends object>(
	emptyFilters: F,
	urlParams: URLSearchParams,
	setUrlParams: (newUrlParams: URLSearchParams) => void,
	urlParamsToFilters: (urlParams: URLSearchParams, emptyFilters: F) => F,
	filtersToUrlParams: (filters: F) => Record<string, string>
): [F, (newFilters: F) => void] {
	const filters = useMemo<F>(
		() => urlParamsToFilters(urlParams, emptyFilters),
		[urlParams]
	);
	const setFilters = useCallback(
		(newFilters: F) => {
			const filterParams = filtersToUrlParams(newFilters);
			const unchangedParams = Object.fromEntries(
				Object.entries(Object.fromEntries(urlParams.entries())).filter(
					([k]) => !(k in newFilters)
				)
			);
			setUrlParams(
				new URLSearchParams({
					...unchangedParams,
					...filterParams
				})
			);
		},
		[urlParams, setUrlParams]
	);
	return [filters, setFilters];
}
