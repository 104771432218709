import {
	BackofficeFilterForm,
	FilterForm,
	FilterFormFooter,
	FilterFormHeader
} from "components/BackofficeFilterForm";
import Input from "components/Input";
import MultiSelect from "components/SelectForm/MultiSelect";
import { isAfter, isBefore } from "date-fns";
import { useFormik } from "formik";
import { DateTimeHelper, ErrorHelper } from "helpers";
import OptionHelper from "helpers/OptionHelper";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import { ApplicationFilterOptions } from "services/types";
import * as Yup from "yup";

export interface ApplicationListFilters extends Record<string, unknown> {
	from: string;
	to: string;
	statuses: string[];
	interventionTypes: string[];
	concessionaires: string[];
	responsibleGroups: string[];
}

function validateFutureDate(date: string | undefined) {
	if (date == null) return true;
	return !isAfter(date, DateTimeHelper.currentDate());
}

function validateDatesOrder(from: string | undefined, to: string | undefined) {
	if (to == null || from == null) return true;
	return !isBefore(to, from);
}

const applicationFiltersValidationSchema = Yup.object().shape({
	from: Yup.string().test(
		"valid-from-date",
		"A data inicial é inválida",
		function validateFromDate(from) {
			return validateFutureDate(from);
		}
	),
	to: Yup.string().test(
		"valid-to-date",
		"A data final é inválida",
		function validateToDate(to) {
			return validateFutureDate(to) && validateDatesOrder(this.parent.from, to);
		}
	),
	statuses: Yup.array().of(Yup.string())
});

function getFromMaxDate(to: string | undefined) {
	if (to) return to;
	return DateTimeHelper.currentDate();
}

function getToMinDate(from: string | undefined) {
	return from;
}

function getToMaxDate() {
	return DateTimeHelper.currentDate();
}

export default function BackofficeApplicationListFilterForm({
	initialFilters,
	setFilters,
	clearFilters,
	isOpen,
	setIsOpen,
	filterOptions,
	setFilterOptions,
	isLoadingFilterOptions,
	setIsLoadingFilterOptions,
	applicationService
}: Readonly<{
	initialFilters: ApplicationListFilters;
	setFilters: (newFilters: ApplicationListFilters) => void;
	clearFilters: () => void;
	isOpen: boolean;
	setIsOpen: (newIsOpen: boolean) => void;
	filterOptions: ApplicationFilterOptions | null;
	setFilterOptions: (options: ApplicationFilterOptions) => void;
	isLoadingFilterOptions: boolean;
	setIsLoadingFilterOptions: (isLoading: boolean) => void;
	applicationService: ApplicationService;
}>): JSX.Element {
	const formik = useFormik({
		initialValues: initialFilters,
		validationSchema: applicationFiltersValidationSchema,
		enableReinitialize: true,
		onSubmit: (newFilters) => {
			setFilters(newFilters);
			setIsOpen(false);
		}
	});

	const loadOptions = useCallback(() => {
		setIsLoadingFilterOptions(true);
		applicationService
			.getApplicationFilterOptions()
			.then((responseData) => setFilterOptions(responseData))
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoadingFilterOptions(false));
	}, [applicationService, setFilterOptions, setIsLoadingFilterOptions]);

	useEffect(() => {
		if (filterOptions == null && !isLoadingFilterOptions) loadOptions();
	}, []);

	const { values, handleChange, dirty, errors, handleSubmit, setFieldValue } =
		formik;
	const hasErrors = !!Object.keys(errors).length;

	return (
		<BackofficeFilterForm
			isOpen={isOpen}
			onClickOutside={() => setIsOpen(false)}
		>
			<FilterFormHeader onClose={() => setIsOpen(false)} title="Filtros" />
			<FilterForm
				onSubmit={handleSubmit}
				isLoading={!filterOptions || isLoadingFilterOptions}
			>
				{filterOptions && (
					<>
						<div>Buscar por data de criação:</div>
						<fieldset className="flex flex-col sm:flex-row gap-4">
							<Input
								type="date"
								name="from"
								value={values.from}
								label="De: "
								onChange={handleChange}
								required={false}
								showOptionalIndicator={false}
								max={getFromMaxDate(values.to)}
							/>
							<Input
								type="date"
								name="to"
								value={values.to}
								label="Até: "
								onChange={handleChange}
								required={false}
								showOptionalIndicator={false}
								min={getToMinDate(values.from)}
								max={getToMaxDate()}
							/>
						</fieldset>

						<MultiSelect
							label="Status"
							name="statuses"
							value={values.statuses}
							options={filterOptions.statuses.map(OptionHelper.stringToOption)}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>

						<MultiSelect
							label="Concessionárias"
							name="concessionaires"
							value={values.concessionaires}
							options={filterOptions.concessionaires}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>

						{filterOptions.groups.length > 1 && (
							<MultiSelect
								label="Grupo responsável"
								name="responsibleGroups"
								value={values.responsibleGroups}
								options={filterOptions.groups}
								getOptionValue={OptionHelper.getValue}
								isClearable
								isSearchable
								isDisabled={false}
								setFieldValue={setFieldValue}
								asSimpleValues
							/>
						)}

						<MultiSelect
							label="Tipos de intervenção"
							name="interventionTypes"
							value={values.interventionTypes}
							options={filterOptions.interventionTypes.map(
								OptionHelper.stringToOption
							)}
							getOptionValue={OptionHelper.getValue}
							isClearable
							isSearchable
							isDisabled={false}
							setFieldValue={setFieldValue}
							asSimpleValues
						/>
					</>
				)}
			</FilterForm>
			<FilterFormFooter
				dirty={dirty}
				hasErrors={hasErrors}
				onClear={() => {
					formik.resetForm();
					clearFilters();
				}}
				onSubmit={formik.submitForm}
			/>
		</BackofficeFilterForm>
	);
}
