import { CellContext, ColumnDefTemplate } from "@tanstack/react-table";
import { Constants } from "appConstants";
import { ApplicationListItem } from "components/BackofficeApplicationList";
import { ApplicationRegistryItem } from "components/BackofficeApplicationRegistry";
import { differenceInCalendarDays } from "date-fns";
import { DateTimeHelper } from "helpers";
import { last } from "helpers/ArrayHelper";
import NameHelper from "helpers/NameHelper";
import { AnalysisStatusCode, InternalUserStatusLabel } from "models/types";
import { ReactNode } from "react";
import { AnalysisStatusLabel } from "services/types";

export type ValueDisplay<L extends Record<string, any>, K extends keyof L> = (
	props: Readonly<{
		value: L[K];
		item?: L;
	}>
) => JSX.Element;

export function asCellRenderer<
	L extends Record<string, any>,
	K extends keyof L
>(
	ValueDisplayComponent: ValueDisplay<L, K>
): ColumnDefTemplate<CellContext<L, L[keyof L]>> {
	// eslint-disable-next-line func-names
	return function (props: CellContext<L, L[keyof L]>): JSX.Element {
		const { row, getValue } = props;
		const { original: item } = row;
		return <ValueDisplayComponent value={getValue()} item={item} />;
	};
}

export function BasicValueDisplay<V extends ReactNode>({
	value
}: Readonly<{
	value?: V | null;
}>): JSX.Element {
	return <span className="truncate ellipsis">{value}</span>;
}

export function NameValueDisplay({
	value
}: Readonly<{
	value: string | null;
}>): JSX.Element {
	const abbreviatedName = NameHelper.abbreviateName(value ?? "");
	return <span>{abbreviatedName}</span>;
}

export function FileExtensionDisplay({
	value
}: Readonly<{
	value: string | null;
}>): JSX.Element {
	return <span>{value && last(value.split("."))}</span>;
}

export function NaiveDateValueDisplay({
	value
}: Readonly<{
	value: string | null;
	item?: Record<string, any>;
}>): JSX.Element {
	if (value === null) return <span />;
	const dateString = DateTimeHelper.formatDateString(
		value,
		Constants.date.LOCALE_DATE_SHORT_YEAR_FORMAT
	);
	return <span>{dateString}</span>;
}

function BaseLocaleDateValueDisplay({
	value,
	format
}: Readonly<{
	value: string | null;
	item?: Record<string, any>;
	format?: string;
}>) {
	if (value === null) return <span />;
	const dateString = DateTimeHelper.formatDate(
		DateTimeHelper.fromUtcDate(new Date(value)),
		format
	);
	return <span>{dateString}</span>;
}

export function LocaleDateValueDisplay(
	props: Readonly<{
		value: string | null;
		item?: Record<string, any>;
	}>
): JSX.Element {
	return BaseLocaleDateValueDisplay({
		...props,
		format: Constants.date.LOCALE_DATE_FORMAT
	});
}

export function LocaleDateShortValueDisplay(
	props: Readonly<{
		value: string | null;
		item?: Record<string, any>;
	}>
): JSX.Element {
	return BaseLocaleDateValueDisplay({
		...props,
		format: Constants.date.LOCALE_DATE_SHORT_YEAR_FORMAT
	});
}

interface SemanticColors {
	background: string;
	text: string;
}

const warningColors: SemanticColors = {
	background: "bg-feedback-alert-high-50",
	text: "text-feedback-alert-low-950"
} as const;
const successColors: SemanticColors = {
	background: "bg-feedback-positive-high-50",
	text: "text-feedback-positive-low-950"
};
const errorColors: SemanticColors = {
	background: "bg-feedback-negative-high-50",
	text: "text-feedback-negative-low-950"
};

const pendingStatuses: InternalUserStatusLabel[] = [
	InternalUserStatusLabel.ANDAMENTO,
	InternalUserStatusLabel.MENSAGEM_RECEBIDA,
	InternalUserStatusLabel.PENDENTE
];

const applicationStatusColors: Record<InternalUserStatusLabel, SemanticColors> =
	{
		"Em andamento": successColors,
		"Mensagem enviada": successColors,
		"Mensagem recebida": warningColors,
		Cancelado: errorColors,
		Concluído: successColors,
		Pendente: warningColors,
		Rascunho: warningColors
	};

export function ApplicationListStatusDisplay({
	value
}: Readonly<{
	value: string | null;
	item?: ApplicationListItem;
}>): JSX.Element {
	if (!value) return <span />;
	const { text, background } =
		applicationStatusColors[value as ApplicationListItem["status"]];
	return (
		<span className={`rounded-lg py-1 px-2 text-xs ${text} ${background}`}>
			{value}
		</span>
	);
}

const analysisStatusColors: Record<AnalysisStatusCode, SemanticColors> = {
	APROVADO: successColors,
	APROVADO_RESSALVAS: successColors,
	CANCELADO: errorColors,
	EM_ANDAMENTO: warningColors,
	REPROVADO: errorColors
};

export function ApplicationRegistryStatusDisplay({
	value
}: Readonly<{
	value: string | null;
	item?: ApplicationRegistryItem;
}>): JSX.Element {
	if (!value) return <span />;
	const status = value as ApplicationRegistryItem["currentAnalysisStatus"];
	const { text, background } = analysisStatusColors[status];
	const statusText = AnalysisStatusLabel[status];
	return (
		<span className={`rounded-lg py-1 px-2 text-xs ${text} ${background}`}>
			{statusText}
		</span>
	);
}

function formatRemainingDaysMessage(remainingDays: number): string {
	if (remainingDays > 0) return `${remainingDays} dias`;
	if (remainingDays === 0) return "Hoje";
	const daysMessage = -remainingDays > 1 ? "dias" : "dia";
	return `Atrasado ${-remainingDays} ${daysMessage}`;
}

export function DeadlineDisplay({
	item
}: Readonly<{
	value: string | null;
	item?: ApplicationListItem;
}>): JSX.Element {
	if (!item) return <div />;
	const { analysisStartDatetime, analysisLimitDatetime, status } = item;
	const shouldShowDeadline = pendingStatuses.find((pS) => pS === status);
	if (!shouldShowDeadline) return <div />;
	const currentDate = new Date();
	const startDate = DateTimeHelper.parseDateWithoutTime(analysisStartDatetime);
	const deadlineDate = DateTimeHelper.parseDateWithoutTime(
		analysisLimitDatetime
	);
	const totalDays = differenceInCalendarDays(deadlineDate, startDate);
	const remainingDays = differenceInCalendarDays(deadlineDate, currentDate);
	const message = formatRemainingDaysMessage(remainingDays);

	const filledPercent = (totalDays - Math.max(remainingDays, 0)) / totalDays;

	const bgClassName =
		remainingDays > 10
			? "bg-feedback-positive-pure-500"
			: remainingDays >= 0
			? "bg-feedback-alert-pure-500"
			: "bg-feedback-negative-pure-500";

	return (
		<div className="flex flex-col gap-1 w-full h-full items-center justify-center">
			<span>{message}</span>
			<div className="flex flex-row justify-start h-1 items-center rounded-full w-full bg-neutral-high-100">
				<span
					style={{
						width: `${filledPercent * 100}%`
					}}
					className={`h-1 rounded ${bgClassName}`}
				/>
			</div>
		</div>
	);
}
