import { IcoClose, IcoDownload, IcoFile, IcoFolder } from "assets/icons";
import clsx from "clsx";
import Button from "components/Button";
import { FolderDisplayItem } from "components/FolderDisplay";
import Modal from "components/Modal";
import { DownloadHelper } from "helpers";
import { AnalysisEventInternalListItem } from "models/types";
import { useCallback, useMemo } from "react";
import { ApplicationDocumentService } from "services/applicationDocument";
import {
	AnalysisEventDocument,
	ApplicationFormDocumentFolder
} from "services/types";

export default function EventDetailsModal({
	eventData,
	isOpen,
	onClose,
	applicationDocumentService
}: Readonly<{
	eventData: AnalysisEventInternalListItem;
	isOpen: boolean;
	onClose: () => void;
	applicationDocumentService?: ApplicationDocumentService;
}>): JSX.Element {
	const { description, documents } = eventData;
	const {
		documentFolders,
		singleDocuments
	}: {
		documentFolders: ApplicationFormDocumentFolder[];
		singleDocuments: AnalysisEventDocument[];
	} = useMemo(
		() =>
			documents.reduce<{
				documentFolders: ApplicationFormDocumentFolder[];
				singleDocuments: AnalysisEventDocument[];
			}>(
				(acc, doc) => {
					if (doc.applicationDocumentFolder) {
						let folder = acc.documentFolders.find(
							(f) => f.id === doc.applicationDocumentFolder
						);
						if (!folder) {
							folder = {
								id: doc.applicationDocumentFolder,
								interventionDocumentFolderType:
									doc.interventionDocumentFolderType!,
								documentTypeName: doc.interventionDocumentFolderTypeName!,
								documents: []
							};
							acc.documentFolders.push(folder);
						}
						folder.documents.push(doc);
					} else {
						acc.singleDocuments.push(doc);
					}
					return acc;
				},
				{ documentFolders: [], singleDocuments: [] }
			),
		[documents]
	);

	const performDownload = useCallback(
		(document: AnalysisEventDocument) => {
			const { blobName, name } = document;
			applicationDocumentService?.download(blobName).then((response) => {
				const { downloadUrl } = response;
				DownloadHelper.performDownload(downloadUrl, name);
			});
		},
		[applicationDocumentService]
	);

	const showDocuments = !!documents.length;
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			contentClassName="rounded-lg overflow-hidden flex md:max-w-screen-lg max-w-[94vw] max-h-[98vh] min-h-[66vh] p-0"
			bodyClassName="flex flex-col p-0 gap-0 w-full"
		>
			<header className="h-[4.5rem] flex p-6 items-center justify-between border-b border-neutral-high-200">
				<h1 className="text-lg font-semibold">Detalhes</h1>
				<Button kind="icon" hierarchy="ghost" onClick={onClose}>
					<IcoClose />
				</Button>
			</header>
			<div className="flex flex-col md:flex-row h-full overflow-y-auto text-sm">
				<div
					className={clsx(
						"grow md:min-w-[20rem] md:h-full w-full p-6 gap-6 md:overflow-y-auto",
						{ "md:w-3/6": showDocuments }
					)}
				>
					<h2 className="text-base font-semibold">Descrição</h2>
					<div className="w-full md:max-h-full">
						{description.split("\n").map((paragraph, index) => (
							<p key={`${paragraph[0]}-${index}`}>{paragraph}</p>
						))}
					</div>
				</div>
				{showDocuments && applicationDocumentService && (
					<>
						<div className="w-full h-[1px] md:h-full md:w-[1px] bg-neutral-high-200" />
						<div className="md:w-3/6 grow md:min-w-[20rem] h-full w-full flex flex-col p-6 md:overflow-y-auto">
							<h2 className="text-base font-semibold">Anexos</h2>
							<ul className="flex flex-col w-full gap-6 pt-8 md:max-h-full">
								{documentFolders.map((folder) => (
									<li
										key={folder.id}
										className="base-accordion-item base-accordion-item-expanded border-none"
									>
										<FolderDisplayItem
											key={folder.id}
											applicationDocumentService={applicationDocumentService}
											folder={folder}
											defaultOpen={[""]}
											columns={[
												{
													name: (
														<div className="flex flex-column gap-2 items-center font-body-regular-small">
															<IcoFolder />
															{folder.documentTypeName}
														</div>
													),
													size: 12
												}
											]}
										/>
									</li>
								))}
								{singleDocuments.map((documentData) => (
									<li
										key={documentData.id}
										className="px-2 rounded-lg flex flex-row w-full items-center justify-between gap-2 border border-neutral-high-200"
									>
										<div className="flex items-center gap-2">
											<span className="flex flex-col justify-center items-center min-w-6">
												<IcoFile />
											</span>
											<span className="text-ellipsis overflow-hidden">
												{documentData.name}
											</span>
										</div>
										{applicationDocumentService && (
											<Button
												kind="icon"
												hierarchy="ghost"
												onClick={() => {
													performDownload(documentData);
												}}
											>
												<IcoDownload size="16" />
											</Button>
										)}
									</li>
								))}
							</ul>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
}
