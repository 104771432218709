import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { TailwindColSpan } from "styles/helpers";

export type TableColumnDef<D, V> = ColumnDef<D, V> & {
	size: number;
	meta?: { cellClass?: string; headerClass?: string };
};

const TableHelper = {
	addClassNames: <D, V>(
		columnDefinitions: TableColumnDef<D, V>[],
		cellClassName: string,
		headerClassName: string
	): TableColumnDef<D, V>[] =>
		columnDefinitions.map((columnDef) => ({
			...columnDef,
			meta: {
				cellClass: clsx(
					cellClassName,
					columnDef.meta?.cellClass,
					TailwindColSpan[columnDef.size]
				),
				headerClass: clsx(
					headerClassName,
					columnDef.meta?.headerClass,
					TailwindColSpan[columnDef.size]
				)
			}
		}))
};

export default TableHelper;
