import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authReducer from "./features/auth/slice";
import newRecordReducer from "./features/newRecord/slice";

const persistConfig = {
	key: "ccr-faixa-dominio",
	storage,
	whitelist: ["auth"]
};

const appReducer = combineReducers({
	auth: authReducer,
	newRecord: newRecordReducer
});

const rootReducer = (state: any, action: any) => {
	if (action.type === "auth/setLogout") {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk]
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
