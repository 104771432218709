import { Accordion, Loading } from "components";
import { AccordionSectionProps } from "components/Accordion";
import { ErrorHelper } from "helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ApplicationDocumentService } from "services/applicationDocument";
import { ApplicationService } from "services/applicationService";
import { ApplicationDocumentFolder, RootFolder } from "services/types";
import { TabComponentProps } from "../../types";
import { FolderList } from "./components";

enum FolderVolumesIds {
	VOLUME_1 = "volume-1",
	VOLUME_2 = "volume-2"
}

export default function ApplicationPageDocuments(
	props: Readonly<TabComponentProps>
): JSX.Element {
	const { application } = props;
	const applicationId = application?.id;
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const applicationDocumentService = useMemo(
		ApplicationDocumentService.getInstance,
		[]
	);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [applicationDocumentFolders, setApplicationDocumentFolders] = useState<
		ApplicationDocumentFolder[]
	>([]);
	const [
		applicationDocumentFolderVersions,
		setApplicationDocumentFolderVersions
	] = useState<{
		[key: string]: number;
	}>({});

	const volumeOneFolders = useMemo(
		() =>
			applicationDocumentFolders.filter(
				(folder) =>
					folder?.interventionDocumentFolderType?.rootFolder ===
					RootFolder.VOLUME_1
			),
		[applicationDocumentFolders]
	);

	const volumeTwoFolders = useMemo(
		() =>
			applicationDocumentFolders.filter(
				(folder) =>
					folder?.interventionDocumentFolderType?.rootFolder ===
					RootFolder.VOLUME_2
			),
		[applicationDocumentFolders]
	);

	const loadInfo = useCallback(() => {
		if (!applicationId) return;
		setIsLoading(true);
		applicationService
			.listApplicationFolders(applicationId)
			.then((responseData) => {
				setApplicationDocumentFolders(responseData);
				setApplicationDocumentFolderVersions(
					responseData.reduce(
						(acc, folder) => ({
							...acc,
							[folder.interventionDocumentFolderType.id]: folder.version
						}),
						{}
					)
				);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoading(false));
	}, [applicationId]);

	useEffect(() => {
		loadInfo();
	}, [applicationId]);

	const replaceDocumentFolderInList = useCallback(
		(
			interventionDocumentFolderTypeId: string,
			newFolder: ApplicationDocumentFolder
		) => {
			setApplicationDocumentFolders((folders) =>
				folders.map((folder) =>
					folder.interventionDocumentFolderType.id ===
					interventionDocumentFolderTypeId
						? newFolder
						: folder
				)
			);
		},
		[]
	);

	const accordionContentSections: AccordionSectionProps[] = useMemo(
		() => [
			{
				items: [
					{
						title:
							"Volume 1 - Relatórios técnicos e documentos de identificação",
						value: FolderVolumesIds.VOLUME_1,
						content: (
							<FolderList
								id={FolderVolumesIds.VOLUME_1}
								folders={volumeOneFolders}
								applicationDocumentService={applicationDocumentService}
								foldersCurrentVersions={applicationDocumentFolderVersions}
								applicationId={applicationId!}
								replaceDocumentFolderInList={replaceDocumentFolderInList}
							/>
						)
					},
					{
						title: "Volume 2 - Projeto e cronograma",
						value: FolderVolumesIds.VOLUME_2,
						content: (
							<FolderList
								id={FolderVolumesIds.VOLUME_2}
								folders={volumeTwoFolders}
								applicationDocumentService={applicationDocumentService}
								foldersCurrentVersions={applicationDocumentFolderVersions}
								applicationId={applicationId!}
								replaceDocumentFolderInList={replaceDocumentFolderInList}
							/>
						)
					}
				]
			}
		],
		[volumeOneFolders, volumeTwoFolders, applicationDocumentService]
	);

	return isLoading ? (
		<div className="bg-neutral-high-pure-50 rounded-lg flex flex-col justify-start grow overflow-hidden">
			<div className="h-full content-center">
				<Loading size={50} />
			</div>
		</div>
	) : (
		<div className="bg-neutral-high-pure-50 rounded-lg flex py-6 flex-col justify-start grow overflow-hidden">
			<div className="w-full px-6 overflow-auto overflow-wrap--anywhere scrollbar-width--thin scrollbar-gutter--stable">
				<Accordion
					sections={accordionContentSections}
					defaultValue={[FolderVolumesIds.VOLUME_1, FolderVolumesIds.VOLUME_2]}
				/>
			</div>
		</div>
	);
}
