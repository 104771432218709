import { Constants } from "appConstants";
import { BaseDocumentFolder, DocumentFolderType } from "services/types";
import * as Yup from "yup";

const ValidationHelper = {
	validateFolderDocumentCount(
		documentFolder: BaseDocumentFolder,
		folderTypeData: DocumentFolderType | undefined,
		forceRequired = false
	): boolean {
		const { documents } = documentFolder;
		const isTestExecutable = folderTypeData && documents;
		return Boolean(
			!isTestExecutable ||
				(!folderTypeData.alwaysRequired && !forceRequired) ||
				documents.length >= folderTypeData.minAmountDocuments
		);
	},
	validateMaximumDocumentCount(value: Record<string, BaseDocumentFolder>) {
		const totalDocuments = Object.values(value).reduce(
			(acc, folder) => acc + folder.documents.length,
			0
		);
		return totalDocuments <= Constants.MAX_NEW_APPLICATION_FILES;
	},
	documentMinimumCountMessage:
		"Adicione a quantidade mínima de arquivos para a pasta",

	buildDocumentFoldersSchema(
		folderKeys: string[],
		folderTypeById: Record<string, DocumentFolderType>,
		baseFolderSchema: Yup.AnyObjectSchema,
		forceAllRequired = false
	): Yup.AnyObjectSchema {
		return Yup.object()
			.shape(
				Object.fromEntries(
					folderKeys.map((folderKey) => [
						folderKey,
						baseFolderSchema.test(
							"minDocumentsTest",
							ValidationHelper.documentMinimumCountMessage,
							// eslint-disable-next-line func-names
							function (value: BaseDocumentFolder) {
								return ValidationHelper.validateFolderDocumentCount(
									value,
									folderTypeById[value.interventionDocumentFolderType],
									forceAllRequired
								);
							}
						)
					])
				)
			)
			.test(
				"maxDocumentsAmount",
				`É possível anexar no máximo ${Constants.MAX_NEW_APPLICATION_FILES} arquivos`,
				// eslint-disable-next-line func-names
				function (val) {
					const totalDocuments = Object.values(
						val as Record<string, BaseDocumentFolder>
					).reduce((acc, folder) => acc + folder.documents.length, 0);
					return totalDocuments <= Constants.MAX_NEW_APPLICATION_FILES;
				}
			);
	}
};
export default ValidationHelper;
