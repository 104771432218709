import { ColumnDef } from "@tanstack/react-table";
import { IcoFilter, IcoSearch } from "assets/icons";
import Table from "components/Table";
import { PaginationTools } from "hooks/usePagination";
import { ReactNode, useMemo } from "react";

import colors from "colors";
import Button from "components/Button";
import Input from "components/Input";
import Pagination from "components/Pagination";
import { ApplicationPageTabs } from "pages/Backoffice/ApplicationPage/types";
import { useNavigate } from "react-router-dom";
import { TailwindColSpan } from "styles/helpers";
import "./styles.scss";

export interface SearchTools {
	stableSearchText: string;
	searchText: string;
	setSearchText: (newText: string) => void;
}

export type ListFilters = Record<string, unknown>;

export interface FilterTools<F extends Record<string, unknown>> {
	filters: F;
	initialFilters: F;
	setFilters: (newFilters: F) => void;
	setModalOpen: (newModalOpen: boolean) => void;
	resetFilters: () => void;
}

export type ListItem = Record<string, unknown>;

export interface TableHeader<L extends ListItem, K extends keyof L> {
	accessorKey: K;
	displayHeader: ReactNode;
	cell: (props: { row: { original: L } }) => JSX.Element;
	size: number;
}

export interface ListItemAction<L extends ListItem> {
	key: string;
	displayAction: ReactNode;
	executeAction: (evt: React.MouseEvent, item: L) => void;
}

interface BackofficeListProps<L extends ListItem, F extends ListFilters> {
	title: string;
	columns: ColumnDef<L, L[keyof L]>[];
	items: L[];
	loading?: boolean;
	actions?: ListItemAction<L>[];
	paginationTools: PaginationTools;
	searchTools?: SearchTools;
	filterTools?: FilterTools<F>;
}

export default function BackofficeList<
	L extends ListItem,
	F extends ListFilters
>({
	title,
	columns,
	items,
	paginationTools,
	searchTools,
	filterTools,
	loading
}: Readonly<BackofficeListProps<L, F>>): JSX.Element {
	const navigate = useNavigate();
	const filterCount = useMemo(
		() =>
			Object.values(filterTools?.filters ?? {}).filter((value) =>
				Array.isArray(value) ? !!value.length : !!value
			).length,
		[filterTools?.filters]
	);
	const hasFilters = !!filterCount;

	return (
		<div className="h-full flex flex-col">
			<div className="h-full px-6 py-6 flex flex-col gap-3">
				<header className="flex flex-col">
					<div className="flex flex-row justify-between items-center">
						<div className="flex flex-row justify-start items-center gap-3">
							<h1 className="text-xl font-semibold">{title}</h1>
						</div>
						<div className="flex flex-row justify-end items-center gap-6">
							{filterTools && (
								<div className="flex flex-row items-center gap-2">
									{(hasFilters || searchTools?.stableSearchText) && (
										<Button
											onClick={() => {
												filterTools.resetFilters();
												searchTools?.setSearchText("");
											}}
											hierarchy="ghost"
											className="bg-transparent max-h-10 min-h-unset"
										>
											<u className="text-sm">Limpar filtros</u>
										</Button>
									)}
									<Button
										kind="chip"
										hierarchy={hasFilters ? "primary" : "secondary"}
										onClick={() => filterTools.setModalOpen(true)}
									>
										{!hasFilters && <IcoFilter color="#1d1d1d" />}
										<span>Filtros</span>
										{hasFilters && (
											<span className="p1 h-4 w-4 flex items-center justify-center rounded-full bg-neutral-high-pure-50 text-neutral-low-pure-500">
												{filterCount}
											</span>
										)}
									</Button>
								</div>
							)}
							{filterTools && searchTools && (
								<div className="w-0.5 h-8 bg-neutral-high-100" />
							)}
							{searchTools && (
								<Input
									name="search"
									value={searchTools.searchText}
									onChange={(evt) =>
										searchTools.setSearchText(evt.target.value)
									}
									placeholder="Buscar nº do processo ou solicitante"
									inputClassName="h-10 w-96 rounded text-base appearance-none"
									type="search"
								/>
							)}
						</div>
					</div>
				</header>
				<section className="flex flex-col bg-neutral-high-pure-50 rounded-lg overflow-hidden">
					{items.length || loading ? (
						<>
							<div className="overflow-auto min-h-[calc(100vh-14.25rem)] scrollbar-width--thin">
								<Table
									isLoading={loading}
									columns={columns}
									data={items}
									onRowClick={(row) =>
										navigate(
											`/backoffice/solicitacao/${row.original.id}/${ApplicationPageTabs.DETAILS}`
										)
									}
									headerRowClassName="grid grid-cols-60 border-b-2 border-neutral-low-pure-500"
									bodyRowClassName="grid grid-cols-60 border-b border-neutral-high-100 cursor-pointer hover:bg-neutral-high-200"
									emptyListCellClassName={`flex items-center justify-start border-0 text-sm text-neutral-low-300 h-12 p-4 ${TailwindColSpan[12]}`}
								/>
							</div>
							<Pagination
								totalPages={paginationTools.pageCount}
								currentPage={paginationTools.stablePage}
								onPageChange={(page, value) => {
									paginationTools.setPage(page);
									paginationTools.setPageItemCount(Number(value));
								}}
								pageSize={paginationTools.pageItemCount}
							/>
						</>
					) : (
						<div className="flex flex-col w-full h-[calc(100vh-11rem)] items-center justify-center">
							<div className="flex flex-col items-center justify-center gap-4">
								<div className="flex flex-col items-center justify-center p-3 bg-neutral-high-100 rounded-full">
									<IcoSearch size="32" color={colors.neutral["low-pure-500"]} />
								</div>
								<div className="flex flex-col items-center justify-center">
									<strong>Nenhum resultado encontrado</strong>
									<span className="text-sm">Sua lista está vazia</span>
								</div>
							</div>
						</div>
					)}
				</section>
			</div>
		</div>
	);
}
