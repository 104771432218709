import { ColumnDef } from "@tanstack/react-table";
import { Constants } from "appConstants";
import { IcoFile } from "assets/icons";
import BackofficeApplicationRegistryFilterForm, {
	ApplicationRegistryFilters
} from "components/BackofficeApplicationRegistryFilterForm";
import BackofficeList, { FilterTools } from "components/BackofficeList";
import {
	ApplicationRegistryStatusDisplay,
	asCellRenderer,
	BasicValueDisplay,
	LocaleDateShortValueDisplay
} from "components/BackofficeList/modules/ValueDisplays";
import Button from "components/Button";
import { ErrorHelper, TableHelper } from "helpers";
import usePagination from "hooks/usePagination";
import useStabilizedState from "hooks/useStabilizedState";
import useUrlFilters from "hooks/useUrlFilters";
import { AnalysisStatusCode } from "models/types";
import { ApplicationPageTabs } from "pages/Backoffice/ApplicationPage/types";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { ApplicationService } from "services/applicationService";
import { ApplicationFilterOptions } from "services/types";

export type ApplicationRegistryItem = {
	id: string;
	applicationProcessCode: string;
	issuedAt: string; // Data em que a solicitação foi originalmente aberta; formato UTC
	concessionaireName: string;
	proprietorName: string;
	interventionTypeName: string;
	highwayCode: string;
	currentUserResponsibleGroup: Constants.UserRoleGroups;
	currentAnalysisStatus: AnalysisStatusCode; // sempre a mais atual
	lastAnalysisEndDatetime: string | null; // a última análise que possuir endDatetime; yyyy-MM-dd
};

const emptyFilters: ApplicationRegistryFilters = {
	from: "",
	to: "",
	concessionaires: [],
	interventionTypes: [],
	lastAnalysisStatuses: [],
	responsibleGroups: []
};

const headerClassName =
	"flex flex-row items-center justify-start border-0 text-xs font-semi text-neutral-low-pure-500 h-12 p-2";
const cellClassName =
	"flex flex-row items-center justify-start border-0 text-sm text-neutral-low-400 h-12 p-2";

type ApplicationRegistryItemColumn = ColumnDef<
	ApplicationRegistryItem,
	ApplicationRegistryItem[keyof ApplicationRegistryItem]
>;

function ActionsCell({
	value
}: Readonly<{ value: string | null }>): JSX.Element {
	const navigate = useNavigate();
	const location = useLocation();
	return (
		<div className="flex flex-row w-full h-full justify-center items-center">
			<Button
				type="button"
				kind="icon"
				hierarchy="ghost"
				className="detail-button"
				onClick={() =>
					navigate(
						`/backoffice/solicitacao/${value}/${ApplicationPageTabs.DETAILS}`,
						{ state: { prevRoute: `/backoffice/consulta${location.search}` } }
					)
				}
			>
				<Tooltip anchorSelect=".detail-button" place="top">
					Detalhes
				</Tooltip>
				<IcoFile />
			</Button>
			{/* <Button type="button" kind="icon" hierarchy="ghost">
				<IcoMessage />
			</Button> */}
		</div>
	);
}

const applicationRegistryColumns: ApplicationRegistryItemColumn[] =
	TableHelper.addClassNames(
		[
			{
				accessorKey: "applicationProcessCode",
				header: "Nº do processo",
				cell: asCellRenderer(BasicValueDisplay),
				size: 6
			},
			{
				accessorKey: "issuedAt",
				header: "Data de criação",
				cell: asCellRenderer<ApplicationRegistryItem, "issuedAt">(
					LocaleDateShortValueDisplay
				),
				size: 6
			},
			{
				accessorKey: "lastAnalysisEndDatetime",
				header: () => (
					<div
						id="lastAnalysisEndDatetimeHeader"
						className="w-full h-full flex flex-row justify-start items-center"
					>
						<Tooltip anchorSelect="#lastAnalysisEndDatetimeHeader" place="top">
							<div className="text-base font-normal max-w-[96vw] md:max-w-[30rem] text-wrap">
								Referente ao último protocolo respondido
							</div>
						</Tooltip>
						<span>Data da resposta</span>
					</div>
				),
				cell: asCellRenderer<
					ApplicationRegistryItem,
					"lastAnalysisEndDatetime"
				>(LocaleDateShortValueDisplay),
				size: 6
			},
			{
				accessorKey: "proprietorName",
				header: "Solicitante",
				cell: asCellRenderer(BasicValueDisplay),
				size: 9
			},
			{
				accessorKey: "currentUserResponsibleGroup",
				header: "Área",
				cell: asCellRenderer(({ value }) => (
					<BasicValueDisplay
						value={Constants.getReadableGroupName(
							value as Constants.UserRoleGroups
						)}
					/>
				)),
				size: 4
			},
			{
				accessorKey: "currentAnalysisStatus",
				header: "Status",
				cell: asCellRenderer(ApplicationRegistryStatusDisplay),
				size: 9,
				meta: { headerClass: "justify-center", cellClass: "justify-center" }
			},
			{
				accessorKey: "concessionaireName",
				header: "Concessionária",
				cell: asCellRenderer(BasicValueDisplay),
				size: 7
			},
			{
				accessorKey: "interventionTypeName",
				header: "Tipo de intervenção",
				cell: asCellRenderer(BasicValueDisplay),
				size: 9
			},
			{
				id: "action",
				accessorKey: "id",
				header: "Ações",
				enableSorting: false,
				cell: asCellRenderer(ActionsCell),
				meta: { headerClass: "justify-center", cellClass: "justify-center" },
				size: 4
			}
		],
		cellClassName,
		headerClassName
	);

export default function BackofficeApplicationRegistry(): JSX.Element {
	const [urlParams, setUrlParams] = useSearchParams();

	const paginationTools = usePagination();
	const { stablePage, pageItemCount, setPageCount, setPage } = paginationTools;

	const {
		setValue: setSearchText,
		stableValue: stableSearchText,
		unstableValue: searchText
	} = useStabilizedState<string>(urlParams.get("search") ?? "", 750);

	const searchTools = {
		setSearchText: (newSearchText: string) => {
			setSearchText(newSearchText);
			setPage(Constants.INITIAL_PAGE_INDEX);
		},
		stableSearchText,
		searchText
	};
	useEffect(() => {
		const newParams = new URLSearchParams(urlParams);
		newParams.set("search", stableSearchText);
		setUrlParams(newParams);
	}, [stableSearchText]);

	const [filters, setFilters] = useUrlFilters(
		emptyFilters,
		urlParams,
		(newParams) => {
			setPage(Constants.INITIAL_PAGE_INDEX);
			setUrlParams(newParams);
		},
		ApplicationService.parseBackofficeRegistryFilterParams,
		ApplicationService.makeBackofficeApplicationFilterParams
	);
	const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

	const filterTools: FilterTools<ApplicationRegistryFilters> = {
		filters,
		initialFilters: emptyFilters,
		resetFilters: () => setFilters(emptyFilters),
		setFilters,
		setModalOpen: setIsFilterModalOpen
	};

	const applicationService = ApplicationService.getInstance();

	const [items, setItems] = useState<ApplicationRegistryItem[] | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [filterOptions, setFilterOptions] =
		useState<ApplicationFilterOptions | null>(null);
	const [isLoadingFilterOptions, setIsLoadingFilterOptions] =
		useState<boolean>(false);

	const loadData = useCallback(() => {
		setIsLoading(true);
		applicationService
			.getAllApplicationsList(
				stablePage - 1,
				pageItemCount,
				stableSearchText,
				filters
			)
			.then((responseData) => {
				setPageCount(responseData.totalPages);
				setItems(responseData.content);
			})
			.catch((err) => toast.error(ErrorHelper.getResponseErrorMessage(err)))
			.finally(() => setIsLoading(false));
	}, [
		stablePage,
		pageItemCount,
		stableSearchText,
		filters,
		setItems,
		setPageCount,
		setIsLoading
	]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<div className="h-full">
			<BackofficeList
				columns={applicationRegistryColumns}
				items={items ?? []}
				loading={isLoading}
				title="Consulta"
				paginationTools={paginationTools}
				searchTools={searchTools}
				filterTools={filterTools}
			/>
			{isFilterModalOpen && (
				<BackofficeApplicationRegistryFilterForm
					setFilters={setFilters}
					clearFilters={() => {
						setFilters(emptyFilters);
						setSearchText("");
					}}
					initialFilters={filters}
					isOpen={isFilterModalOpen}
					setIsOpen={setIsFilterModalOpen}
					filterOptions={filterOptions}
					setFilterOptions={setFilterOptions}
					isLoadingFilterOptions={isLoadingFilterOptions}
					setIsLoadingFilterOptions={setIsLoadingFilterOptions}
				/>
			)}
		</div>
	);
}
