import { OptionLike } from "helpers/OptionHelper";
import {
	AnalysisStatusCodeRequest,
	ApplicationEventType,
	ApplicationStatus
} from "models/types";

export interface BaseListResponse<T = any> {
	empty: boolean;
	first: boolean;
	last: boolean;
	content: T[];
	totalPages: number;
	totalElements: number;
	size: number;
	pageable?: {
		offset: number;
		pageNumber: number;
		pageSize: number;
		paged: boolean;
	};
}

export interface AddressDataResponse {
	status: number;
	ok: boolean;
	code: string;
	state: string;
	city: string;
	district: string;
	address: string;
	statusText: string;
	message?: string;
}

export interface RegisterEventRequest {
	event: {
		type: keyof typeof ApplicationEventType;
		title?: string;
		description?: string;
	};
	documents?: DocumentLike[];
	documentFolders?: AnalysisReplyDocumentFolder[];
	analysisStatusCode?: keyof typeof AnalysisStatusCodeRequest;
}

export interface DocumentLike {
	blobName: string;
	name: string;
	error?: string;
}

export interface BaseDocumentFolder {
	id?: string;
	interventionDocumentFolderType: string;
	documents: DocumentLike[];
}

export interface AnalysisEventDocument extends DocumentLike {
	id: string;
	interventionDocumentFolderType: string | null;
	applicationDocumentFolder: string | null;
	interventionDocumentFolderTypeName: string | null;
}

export interface AnalysisReplyDocumentFolder extends BaseDocumentFolder {
	documents: AnalysisEventDocument[];
}

export interface AnalysisEventDocumentFolder extends BaseDocumentFolder {
	id?: string;
	interventionDocumentFolderType: string;
	rootFolder: RootFolder;
	documents: AnalysisEventDocument[];
	folderTypeData: DocumentFolderType;
}

export interface AnalysisEventData {
	id: string;
	type: ApplicationEventType;
	title: string;
	description: string;
	createdByGroup: string;
	createdAt: string;
}

export enum AnalysisStatusLabel {
	EM_ANDAMENTO = "Em andamento",
	APROVADO = "Aprovado",
	APROVADO_RESSALVAS = "Aprovado com ressalvas",
	REPROVADO = "Reprovado",
	CANCELADO = "Cancelado"
}

export enum RedirectApplicationStatusLabel {
	APROVADO = "Aprovado",
	APROVADO_RESSALVAS = "Aprovado com ressalvas",
	REPROVADO = "Reprovado",
	DEVOLVIDO = "Devolvido",
	APTO_ANALISE = "Apto para análise"
}

export interface ApplicationAnalysisData {
	id: string;
	applicationProcessCode: string;
	applicationStatus: ApplicationStatus;
	analysisCode: string;
	analysisStartDatetime: string;
	analysisEndDatetime: string | null;
	status: AnalysisStatusLabel;
	events: AnalysisEventData[];
}

export interface DocumentFolderType {
	id: string;
	requestedAt: string;
	alwaysRequired: boolean;
	minAmountDocuments: number;
	rootFolder: RootFolder;
	expectedFormatsText: string;
	documentType: {
		id: number;
		name: string;
		details: string;
	};
	documentTemplates: {
		id: number;
		blobName: string;
		description: string;
	}[];
}

export interface FileExtensionData {
	extension: string;
	label: string;
}

export interface DocumentFolderTypesAndFileExtensions {
	documentFolderTypes: DocumentFolderType[];
	fileExtensions: FileExtensionData[];
}

export interface ApplicationFormDocument extends DocumentLike {
	id?: string;
}

export interface ApplicationFormDocumentFolder extends BaseDocumentFolder {
	id?: string;
	interventionDocumentFolderType: string;
	documentTypeName?: string;
	rootFolder?: RootFolder;
	documents: ApplicationFormDocument[];
}

export interface ApplicationDocumentFolder {
	id: string;
	version: number;
	status: ApplicationDocumentFolderStatus;
	createdAt: string;
	interventionDocumentFolderType: InterventionDocumentFolderType;
	documents: ApplicationDocument[];
	rootFolder: RootFolder;
	documentTypeName: string;
}

export interface ApplicationDocument {
	id: string;
	name: string;
	blobName: string;
}

export enum RootFolder {
	VOLUME_1 = 1,
	VOLUME_2 = 2
}

export enum ApplicationDocumentFolderStatus {
	SUBMITTED = "SUBMITTED",
	REJECTED = "REJECTED",
	REQUESTED = "REQUESTED",
	PENDING = "PENDING"
}

export interface DocumentType {
	id: string;
	name: string;
	details: string;
}
export interface InterventionDocumentFolderType {
	id: string;
	rootFolder: RootFolder;
	documentType: DocumentType;
	documentTypeId: string;
}

export enum DocumentRequestedMoment {
	AT_START = "AT_START",
	ON_GOING = "ON_GOING"
}
export interface Paginated<I> {
	content: I[];
	totalPages: number;
}

export interface ApplicationFilterOptions {
	statuses: string[];
	analysisStatuses: OptionLike[];
	groups: OptionLike[];
	interventionTypes: string[];
	concessionaires: OptionLike[];
}
