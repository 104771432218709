import * as Yup from "yup";

import { Constants } from "appConstants";
import {
	Checkbox,
	DetailItem,
	Input,
	SelectedDisplay,
	SelectForm,
	TextArea
} from "components";
import { FieldSetter, useChangeHandler } from "helpers/FieldHandlerHelper";
import { useDataModel } from "hooks/useDataModel";
import { HighwayConcession } from "models/HighwayConcession";
import { InterventionType } from "models/InterventionType";
import { InterventionTypeCategory } from "models/InterventionTypeCategory";
import { HighwayConcessionData } from "models/types";
import { ChangeEvent, ChangeEventHandler, useCallback } from "react";
import { AnyObject } from "yup/lib/types";
import { Section, SectionContent, SectionHeader, SectionItem } from "../layout";

import { applicationDataSections } from ".";
import {
	ApplicationData,
	ApplicationDirectionPosition,
	ApplicationFields,
	ApplicationFormik,
	ApplicationInterventionDirection,
	InterventionDirectionValue
} from "../types";

const minLength = Constants.MIN_APPLICATION_DESCRIPTION_LENGTH;
const minLengthText = `Mínimo de ${minLength} caracteres`;

interface ApplicationTestContext {
	values: ApplicationData;
}

function joinKmAndMeter(kilometers: number, meters: number): number {
	return kilometers * 1000 + meters;
}

function isCastableToNumber(value: any) {
	return !Number.isNaN(parseInt(value, 10));
}

function isInsideHighway(
	kilometers: number,
	meters: number | undefined | null,
	highwayConcession: HighwayConcession | HighwayConcessionData
) {
	let valueForms = kilometers;
	let valueInitialHighway = Number(highwayConcession.initialKilometer);
	let valueFinalHighway = Number(highwayConcession.finalKilometer);
	if (meters !== undefined && meters !== null) {
		valueForms = joinKmAndMeter(kilometers, meters);
		valueInitialHighway = joinKmAndMeter(
			Number(highwayConcession.initialKilometer),
			Number(highwayConcession.initialMeter)
		);
		valueFinalHighway = joinKmAndMeter(
			Number(highwayConcession.finalKilometer),
			Number(highwayConcession.finalMeter)
		);
	}

	return valueInitialHighway <= valueForms && valueFinalHighway >= valueForms;
}

function isInitialInsideHighwayRange(
	this: Yup.TestContext<AnyObject>
): boolean {
	const { values } = this.options.context as ApplicationTestContext;
	const { initialKilometer, initialMeter, highwayConcession } = values;

	return (
		!highwayConcession ||
		isInsideHighway(
			Number(initialKilometer),
			isCastableToNumber(initialMeter) ? Number(initialMeter) : initialMeter,
			highwayConcession
		)
	);
}

function isFinalInsideHighwayRange(this: Yup.TestContext<AnyObject>): boolean {
	const { values } = this.options.context as ApplicationTestContext;
	const { finalKilometer, finalMeter, highwayConcession } = values;

	return (
		!highwayConcession ||
		isInsideHighway(
			Number(finalKilometer),
			isCastableToNumber(finalMeter) ? Number(finalMeter) : finalMeter,
			highwayConcession
		)
	);
}

function isInitialBeforeFinal(this: Yup.TestContext<AnyObject>): boolean {
	const { values } = this.options.context as any;
	const { initialKilometer, finalKilometer, initialMeter, finalMeter } = values;
	if (
		!isCastableToNumber(initialKilometer) ||
		!isCastableToNumber(finalKilometer)
	)
		return true;

	const initial = joinKmAndMeter(
		Number(initialKilometer),
		Number(initialMeter ?? 0)
	);
	const final = joinKmAndMeter(Number(finalKilometer), Number(finalMeter ?? 0));
	return initial <= final;
}

function hasAtLeastOneDirectionEach(this: Yup.TestContext<AnyObject>): boolean {
	const { values } = this.options.context as any;
	const { directions } = values;
	const hasInitialDirectionChecked = directions?.some(
		(direction: ApplicationInterventionDirection) =>
			!!direction.isInitialDirection
	);
	const hasFinalDirectionChecked = directions?.some(
		(direction: ApplicationInterventionDirection) =>
			!direction.isInitialDirection
	);
	return hasInitialDirectionChecked && hasFinalDirectionChecked;
}

const errorMessages = Constants.ErrorMessages;
const overrideNullTest: [
	string,
	string,
	(value: string | null | undefined, ...rest: any[]) => boolean
] = [
	"notNull",
	errorMessages.requiredField,
	(value) => {
		return value !== null && value !== undefined;
	}
];

export const applicationDescriptionDataSchema = {
	initialKilometer: Yup.number()
		.nullable()
		.required(errorMessages.requiredField)
		.min(0, errorMessages.greaterThanZero)
		.test(
			"isInitialInsideHighwayRange",
			errorMessages.mustBeInsideHighwayConcession,
			isInitialInsideHighwayRange
		)
		.test(
			"isLessThanFinalValue",
			errorMessages.initialMustBeBeforeFinal,
			isInitialBeforeFinal
		),
	initialMeter: Yup.number()
		.nullable()
		.min(0, errorMessages.greaterThanZero)
		.max(999, errorMessages.lessThan1000)
		.test(
			"isInitialInsideHighwayRange",
			errorMessages.mustBeInsideHighwayConcession,
			isInitialInsideHighwayRange
		)
		.test(
			"isLessThanFinalValue",
			errorMessages.initialMustBeBeforeFinal,
			isInitialBeforeFinal
		),
	initialUf: Yup.string()
		.nullable()
		.required(errorMessages.requiredField)
		.test(...overrideNullTest),
	finalKilometer: Yup.number()
		.nullable()
		.required(errorMessages.requiredField)
		.min(0, errorMessages.greaterThanZero)
		.test(
			"isFinalInsideHighwayRange",
			errorMessages.mustBeInsideHighwayConcession,
			isFinalInsideHighwayRange
		)
		.test(
			"isLessThanFinalValue",
			errorMessages.initialMustBeBeforeFinal,
			isInitialBeforeFinal
		),
	finalMeter: Yup.number()
		.nullable()
		.min(0, errorMessages.greaterThanZero)
		.max(999, errorMessages.lessThan1000)
		.test(
			"isFinalInsideHighwayRange",
			errorMessages.mustBeInsideHighwayConcession,
			isFinalInsideHighwayRange
		)
		.test(
			"isLessThanFinalValue",
			errorMessages.initialMustBeBeforeFinal,
			isInitialBeforeFinal
		),
	finalUf: Yup.string()
		.nullable()
		.required(errorMessages.requiredField)
		.test(...overrideNullTest),
	directions: Yup.array()
		.test(
			"atLeastOneDirectionOfEach",
			errorMessages.atLeastOneDirection,
			hasAtLeastOneDirectionEach
		)
		.of(
			Yup.object().shape({
				direction: Yup.string().nullable().required(),
				isInitialDirection: Yup.boolean().required()
			})
		),
	description: Yup.string()
		.nullable()
		.required(errorMessages.requiredField)
		.min(minLength, minLengthText)
};

export function convertStringToDirectionsData(value: string) {
	const isInitial = value.substring(0, 7);
	return {
		direction: Number(value.substring(value.length - 1)),
		isInitialDirection: isInitial.includes("initial")
	};
}

export function hasThisDirectionChecked(
	directions: ApplicationInterventionDirection[],
	directionSearched: ApplicationInterventionDirection
): boolean {
	return directions.some(
		(direction) =>
			direction.direction === directionSearched.direction &&
			direction.isInitialDirection === directionSearched.isInitialDirection
	);
}

function useCheckboxChangeHandler<V>(
	setFieldValue: FieldSetter<V>,
	directions: ApplicationInterventionDirection[]
): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> {
	return (evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const newDirection = convertStringToDirectionsData(evt.target.value);
		let newValue;
		if (hasThisDirectionChecked(directions, newDirection)) {
			const directionIndex = directions.findIndex(
				(direction) =>
					direction.direction === newDirection.direction &&
					direction.isInitialDirection === newDirection.isInitialDirection
			);
			newValue = [...directions];
			newValue.splice(directionIndex, 1);
		} else {
			newValue = [...directions, newDirection];
		}
		setFieldValue(evt.target.name, newValue);
	};
}

export default function ApplicationDescriptionDataSection({
	formik
}: Readonly<ApplicationFormik>): JSX.Element {
	const { values, errors, touched, handleBlur } = formik;
	const { highwayConcession, interventionType, interventionTypeCategory } =
		values;

	const setApplicationFieldValue = formik.setFieldValue;
	const handleChange = useChangeHandler(setApplicationFieldValue);

	const handleCheckboxChanges = useCheckboxChangeHandler(
		setApplicationFieldValue,
		values.directions
	);

	const selectedHighway = useDataModel(highwayConcession, HighwayConcession);
	const selectedInterventionType = useDataModel(
		interventionType,
		InterventionType
	);
	const selectedInterventionTypeCategory = useDataModel(
		interventionTypeCategory,
		InterventionTypeCategory
	);

	const getDirectionError = useCallback(
		(currentDirection: ApplicationDirectionPosition, formErrors: any) => {
			const { directions } = values;
			const hasDirections = directions?.some(
				(direction: ApplicationInterventionDirection) => {
					return currentDirection === ApplicationDirectionPosition.initial
						? direction.isInitialDirection
						: !direction.isInitialDirection;
				}
			);
			return hasDirections ? null : formErrors;
		},
		[values.directions]
	);

	return (
		<Section>
			<SectionHeader
				title="Dados da solicitação"
				description="Aqui você deve descrever os detalhes da sua solicitação e informar os dados do responsável legal"
			/>
			<SectionContent title="Rodovia">
				<SectionItem colSpan={12}>
					<div className="my-4 rounded-lg p-4 md:pt-5 md:pb-6 bg-neutral-high-pure-50 flex items-center gap-4 border border-neutral-high-200">
						<SelectedDisplay
							text="Rodovia selecionada"
							isIconVisible
							selected={selectedHighway ? selectedHighway.toString() : ""}
						/>
					</div>
				</SectionItem>
				<SectionItem colSpan={12}>
					<DetailItem
						label="Agência reguladora"
						value={selectedHighway?.concessionaire.regulatoryAgency.code}
					/>
					<DetailItem
						label="Sentido"
						value={selectedHighway?.highway.directionRepresentation}
					/>
					<DetailItem
						label="Km inicial"
						value={selectedHighway?.positionRepresentation(
							ApplicationDirectionPosition.initial
						)}
					/>
					<DetailItem
						label="Km final"
						value={selectedHighway?.positionRepresentation(
							ApplicationDirectionPosition.final
						)}
					/>
				</SectionItem>
			</SectionContent>
			<SectionContent title="Intervenção">
				<SectionItem colSpan={12}>
					<div className="my-4 rounded-lg p-4 md:pt-5 md:pb-6 bg-neutral-high-pure-50 flex items-center gap-4 border border-neutral-high-200">
						<SelectedDisplay
							text="Intervenção selecionada"
							isIconVisible
							selected={
								selectedInterventionType ? selectedInterventionType.name : ""
							}
						/>
					</div>
					{selectedInterventionTypeCategory && (
						<div className="my-4 rounded-lg p-4 md:pt-5 md:pb-6 bg-neutral-high-pure-50 flex items-center gap-4 border border-neutral-high-200">
							<SelectedDisplay
								text="Categoria"
								selected={
									selectedInterventionTypeCategory
										? selectedInterventionTypeCategory.name
										: ""
								}
							/>
						</div>
					)}
				</SectionItem>
			</SectionContent>
			<SectionContent
				title="Informe o km, metro e UF da intervenção"
				hint="O intervalo de onde deseja a intervenção"
				id={`section-${applicationDataSections.initialLocation}`}
			>
				<SectionItem colSpan={12}>Inicial</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.initialKilometer}
						label="km inicial"
						value={values.initialKilometer}
						error={errors?.initialKilometer}
						touched={touched?.initialKilometer}
						onChange={handleChange}
						onBlur={handleBlur}
						min={0}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.initialMeter}
						label="metro inicial"
						value={values.initialMeter}
						error={errors?.initialMeter}
						touched={touched?.initialMeter}
						onChange={handleChange}
						onBlur={handleBlur}
						min={0}
						max={999}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<SelectForm
						name={ApplicationFields.initialUf}
						label="UF inicial"
						value={values.initialUf}
						error={errors?.initialUf}
						touched={touched?.initialUf}
						options={Constants.federalUnitCodeOptions}
						getOptionValue={(option) => option.value}
						placeholder=""
						isDisabled
					/>
				</SectionItem>
			</SectionContent>
			<SectionContent
				title="Informe o sentido da pista inicial"
				hint="O sentido inicial da intervenção"
				id={`section-${applicationDataSections.directions}`}
			>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${
							ApplicationDirectionPosition.initial
						}${selectedHighway?.highway.directionName("ascending")}`}
						label={selectedHighway?.highway.directionName("ascending")}
						value={`${ApplicationDirectionPosition.initial}${highwayConcession?.highway.ascendingDirection}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.initial}${highwayConcession?.highway.ascendingDirection}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.initial,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${
							ApplicationDirectionPosition.initial
						}${selectedHighway?.highway.directionName("descending")}`}
						label={selectedHighway?.highway.directionName("descending")}
						value={`${ApplicationDirectionPosition.initial}${highwayConcession?.highway.descendingDirection}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.initial}${highwayConcession?.highway.descendingDirection}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.initial,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${ApplicationDirectionPosition.initial}${InterventionDirectionValue.CANTEIRO_CENTRAL}`}
						label="Canteiro Central"
						value={`${ApplicationDirectionPosition.initial}${InterventionDirectionValue.CANTEIRO_CENTRAL}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.initial}${InterventionDirectionValue.CANTEIRO_CENTRAL}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.initial,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
			</SectionContent>
			<SectionContent id={`section-${applicationDataSections.finalLocation}`}>
				<SectionItem colSpan={12}>Final</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.finalKilometer}
						label="km final"
						value={values.finalKilometer}
						error={errors?.finalKilometer}
						touched={touched?.finalKilometer}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Input
						type="number"
						name={ApplicationFields.finalMeter}
						label="metro final"
						value={values.finalMeter}
						error={errors?.finalMeter}
						touched={touched?.finalMeter}
						onChange={handleChange}
						onBlur={handleBlur}
						min={0}
						max={999}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<SelectForm
						name={ApplicationFields.finalUf}
						label="UF final"
						value={values.finalUf}
						error={errors?.finalUf}
						touched={touched?.finalUf}
						options={Constants.federalUnitCodeOptions}
						getOptionValue={(option) => option.value}
						placeholder=""
						isDisabled
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent
				title="Informe o sentido da pista final"
				hint="O sentido final da intervenção"
			>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${
							ApplicationDirectionPosition.final
						}${selectedHighway?.highway.directionName("ascending")}`}
						label={selectedHighway?.highway.directionName("ascending")}
						value={`${ApplicationDirectionPosition.final}${highwayConcession?.highway.ascendingDirection}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.final}${highwayConcession?.highway.ascendingDirection}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.final,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${
							ApplicationDirectionPosition.final
						}${selectedHighway?.highway.directionName("descending")}`}
						label={selectedHighway?.highway.directionName("descending")}
						value={`${ApplicationDirectionPosition.final}${highwayConcession?.highway.descendingDirection}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.final}${highwayConcession?.highway.descendingDirection}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.final,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
				<SectionItem colSpan={4}>
					<Checkbox
						name={ApplicationFields.directions}
						id={`${ApplicationDirectionPosition.final}${InterventionDirectionValue.CANTEIRO_CENTRAL}`}
						label="Canteiro Central"
						value={`${ApplicationDirectionPosition.final}${InterventionDirectionValue.CANTEIRO_CENTRAL}`}
						checked={hasThisDirectionChecked(
							values.directions,
							convertStringToDirectionsData(
								`${ApplicationDirectionPosition.final}${InterventionDirectionValue.CANTEIRO_CENTRAL}`
							)
						)}
						onChange={handleCheckboxChanges}
						onBlur={handleBlur}
						error={getDirectionError(
							ApplicationDirectionPosition.final,
							errors.directions
						)}
						touched={touched.directions}
					/>
				</SectionItem>
			</SectionContent>

			<SectionContent
				title="Descrição"
				hint="Descreve detalhadamente sua solicitação"
				id={`section-${applicationDataSections.description}`}
			>
				<SectionItem colSpan={12}>
					<TextArea
						label={ApplicationFields.description}
						name={ApplicationFields.description}
						placeholder="Descreva detalhes da solicitação"
						value={values.description}
						onChange={handleChange}
						onBlur={handleBlur}
						maxLength={400}
						minLength={minLength}
						minLengthText={minLengthText}
						error={errors?.description}
						touched={touched?.description}
						showIncreasingCharCount
					/>
				</SectionItem>
			</SectionContent>
		</Section>
	);
}
