import { Constants } from "appConstants";
import { AxiosResponse } from "axios";
import { ApplicationListItem } from "components/BackofficeApplicationList";
import { ApplicationListFilters } from "components/BackofficeApplicationListFilterForm";
import { ApplicationRegistryItem } from "components/BackofficeApplicationRegistry";
import { ApplicationRegistryFilters } from "components/BackofficeApplicationRegistryFilterForm";
import { DateTimeHelper, StringHelper } from "helpers";
import FilterHelper from "helpers/FilterHelper";
import { Application } from "models/Application";
import { ApplicationEvent } from "models/ApplicationEvent";
import {
	AnalysisEventInternalListItem,
	ApplicationEventType
} from "models/types";
import { ApplicationListData } from "pages/LoggedInHome/types";
import {
	ApplicationData,
	ApplicationFields,
	ApplicationFormData
} from "pages/NewApplication/steps/types";
import { get, getWithResponse, remove } from "./axios";
import { BaseServices } from "./baseServices";
import {
	ApplicationAnalysisData,
	ApplicationDocumentFolder,
	ApplicationFilterOptions,
	ApplicationFormDocumentFolder,
	Paginated,
	RegisterEventRequest
} from "./types";

export class ApplicationService extends BaseServices<
	ApplicationData,
	Application
> {
	static readonly baseEndpoint = "applications";
	static readonly draftEndpoint = "draft-application";
	static readonly userApplicationEndpoint = "my-applications";
	static readonly filterOptionsEndpoint = "application-filters";

	constructor() {
		super(Application);
	}

	private cleanDocumentFolderUploadErrors(
		data: ApplicationFormData
	): ApplicationFormData {
		return {
			...data,
			documentFolders: Object.fromEntries(
				Object.entries(data.documentFolders)
					.map(([k, docFolder]): [string, ApplicationFormDocumentFolder] => [
						k,
						{
							...docFolder,
							documents: docFolder.documents.filter((doc) => !doc.error)
						}
					])
					.filter(([, folder]) => folder.documents.length > 0)
			)
		};
	}

	private formatPayloadBeforeSubmit(
		data: ApplicationFormData
	): Record<string, any> {
		const cleanData = this.cleanDocumentFolderUploadErrors(data);

		const payload = {
			...cleanData,
			[ApplicationFields.highwayConcession]: cleanData.highwayConcession?.id,
			[ApplicationFields.interventionType]: cleanData.interventionType?.id,
			[ApplicationFields.interventionTypeCategory]:
				cleanData.interventionTypeCategory?.id,
			[ApplicationFields.documentFolders]: Object.values(
				cleanData.documentFolders
			)
		};

		return payload;
	}

	async listAll() {
		return this.list(ApplicationService.baseEndpoint);
	}

	async myApplications(params: any) {
		return this.listPaginatedRecords<ApplicationListData>(
			ApplicationService.userApplicationEndpoint,
			params
		);
	}

	async retrieveMyApplication(applicationId: string) {
		return this.retrieve(
			`${ApplicationService.userApplicationEndpoint}/${applicationId}`
		);
	}

	static makeBackofficeApplicationFilterParams(
		filters?: ApplicationListFilters | ApplicationRegistryFilters
	): Record<string, string> {
		if (!filters) return {};
		const { from, to, ...multiValueFilters } = filters;
		const dateFilters: { from?: string; to?: string } = {};
		if (FilterHelper.hasValue(from)) {
			dateFilters.from =
				DateTimeHelper.parseDateWithoutTime(from).toISOString();
		}
		if (FilterHelper.hasValue(to)) {
			dateFilters.to = DateTimeHelper.addAlmostADay(
				DateTimeHelper.parseDateWithoutTime(to)
			).toISOString();
		}

		const multiStringFilters = Object.fromEntries(
			Object.entries(multiValueFilters ?? {}).filter(([, v]) =>
				FilterHelper.hasValue(v, false)
			)
		);

		return { ...dateFilters, ...multiStringFilters };
	}

	static parseBackofficeListFilterParams(
		urlParams: URLSearchParams,
		initialValues: ApplicationListFilters
	): ApplicationListFilters {
		const from = urlParams.get("from");
		const to = urlParams.get("to");
		return {
			from: from
				? DateTimeHelper.formatDateString(
						from,
						Constants.date.DEFAULT_DATE_FORMAT
				  )
				: initialValues.from,
			to: to
				? DateTimeHelper.formatDateString(
						DateTimeHelper.subAlmostADay(new Date(to)).toISOString(),
						Constants.date.DEFAULT_DATE_FORMAT
				  )
				: initialValues.to,
			concessionaires:
				urlParams.get("concessionaires")?.split(",") ??
				initialValues.concessionaires,
			interventionTypes:
				urlParams.get("interventionTypes")?.split(",") ??
				initialValues.interventionTypes,
			responsibleGroups:
				urlParams.get("responsibleGroups")?.split(",") ??
				initialValues.responsibleGroups,
			statuses: urlParams.get("statuses")?.split(",") ?? initialValues.statuses
		};
	}

	static parseBackofficeRegistryFilterParams(
		urlParams: URLSearchParams,
		initialValues: ApplicationRegistryFilters
	): ApplicationRegistryFilters {
		const from = urlParams.get("from");
		const to = urlParams.get("to");
		return {
			from: from
				? DateTimeHelper.formatDateString(
						from,
						Constants.date.DEFAULT_DATE_FORMAT
				  )
				: initialValues.from,
			to: to
				? DateTimeHelper.formatDateString(
						DateTimeHelper.subAlmostADay(new Date(to)).toISOString(),
						Constants.date.DEFAULT_DATE_FORMAT
				  )
				: initialValues.to,
			concessionaires:
				urlParams.get("concessionaires")?.split(",") ??
				initialValues.concessionaires,
			interventionTypes:
				urlParams.get("interventionTypes")?.split(",") ??
				initialValues.interventionTypes,
			responsibleGroups:
				urlParams.get("responsibleGroups")?.split(",") ??
				initialValues.responsibleGroups,
			lastAnalysisStatuses:
				urlParams.get("lastAnalysisStatuses")?.split(",") ??
				initialValues.lastAnalysisStatuses
		};
	}

	async listBackofficeApplications(
		page: number,
		size: number,
		search?: string,
		filters?: ApplicationListFilters
	): Promise<Paginated<ApplicationListItem>> {
		const filterParams =
			ApplicationService.makeBackofficeApplicationFilterParams(filters);

		return get(`${ApplicationService.baseEndpoint}`, {
			params: {
				page,
				size,
				search,
				...filterParams
			},
			paramsSerializer: StringHelper.serializeQueryParams
		});
	}

	async getAllApplicationsList(
		page: number,
		size: number,
		search?: string,
		filters?: ApplicationRegistryFilters
	): Promise<Paginated<ApplicationRegistryItem>> {
		const filterParams =
			ApplicationService.makeBackofficeApplicationFilterParams(filters);
		const queryParams = {
			page,
			size,
			search,
			...filterParams
		};

		return get(`${ApplicationService.baseEndpoint}/all`, {
			params: queryParams,
			paramsSerializer: StringHelper.serializeQueryParams
		});
	}

	async getApplicationFilterOptions(
		allGroups?: boolean
	): Promise<ApplicationFilterOptions> {
		const params = allGroups ? { allGroups } : {};
		return get(`${ApplicationService.filterOptionsEndpoint}`, {
			params,
			paramsSerializer: StringHelper.serializeQueryParams
		});
	}

	async createDraftApplication(
		data: ApplicationFormData
	): Promise<Application> {
		const payload = this.formatPayloadBeforeSubmit(data);
		return this.create(ApplicationService.draftEndpoint, payload);
	}

	async editDraftApplication(
		applicationId: string,
		data: ApplicationFormData
	): Promise<ApplicationData> {
		const payload = this.formatPayloadBeforeSubmit(data);
		return this.edit(
			`${ApplicationService.draftEndpoint}/${applicationId}`,
			payload
		);
	}

	async commitApplication(
		applicationId: string,
		data: ApplicationFormData
	): Promise<ApplicationEvent> {
		const payload = this.formatPayloadBeforeSubmit(data);
		return this.edit(
			`${ApplicationService.draftEndpoint}/${applicationId}`,
			payload
		).then(() => {
			const eventData: RegisterEventRequest = {
				event: {
					type: ApplicationEventType.SOLICITACAO_CRIADA
				}
			};
			return this.registerEvent(applicationId, eventData);
		});
	}

	async getCurrentDraft(): Promise<AxiosResponse<ApplicationData>> {
		return getWithResponse(ApplicationService.draftEndpoint, {
			validateStatus: (status) => status === 200 || status === 404
		});
	}

	async deleteDraft(): Promise<AxiosResponse<any>> {
		return remove(ApplicationService.draftEndpoint);
	}

	async registerEvent(
		applicationId: string,
		data: RegisterEventRequest
	): Promise<ApplicationEvent> {
		const url = `${ApplicationService.baseEndpoint}/${applicationId}/events`;
		return this.post(url, data).then((response) =>
			ApplicationEvent.createFromResponse(response)
		);
	}

	async listApplicationFolders(
		applicationId: string
	): Promise<ApplicationDocumentFolder[]> {
		const url = `${ApplicationService.baseEndpoint}/${applicationId}/documents`;
		return this.get(url);
	}

	async getApplicationEvents(
		applicationId: string,
		page: number,
		size: number
	): Promise<Paginated<AnalysisEventInternalListItem>> {
		const url = `${ApplicationService.baseEndpoint}/${applicationId}/events`;
		return this.listPaginatedRecords(url, { page, size });
	}

	async getAnalysesList(
		applicationId: string,
		page: number,
		size: number
	): Promise<Paginated<ApplicationAnalysisData>> {
		const url = `${ApplicationService.userApplicationEndpoint}/${applicationId}/analyses`;
		return this.listPaginatedRecords(url, { page, size });
	}

	async getApplicationDetails(applicationId: string) {
		const url = `${ApplicationService.baseEndpoint}/${applicationId}/details`;
		return this.retrieve(url);
	}

	async getApplicationDocumentFolderByVersion(
		applicationId: string,
		interventionTypeCategoryId: string,
		version: number | string
	): Promise<ApplicationDocumentFolder> {
		const url = `${ApplicationService.baseEndpoint}/${applicationId}/folder-types/${interventionTypeCategoryId}/${version}`;
		return this.get(url);
	}

	static getInstance() {
		return new ApplicationService();
	}
}
