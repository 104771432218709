import { BackButton, SegmentedButton } from "components";
import { ErrorHelper } from "helpers";
import NameHelper from "helpers/NameHelper";
import useUserToken from "hooks/useUserToken";
import { Application } from "models/Application";
import { UserAuthorities } from "models/auth/types";
import { ApplicationEventType } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ApplicationService } from "services/applicationService";
import { ConfirmResponsibleModal, ResponsibleUserButton } from "./components";
import ApplicationPageDetails from "./tabs/Details";
import ApplicationPageDocuments from "./tabs/Documents";
import ApplicationPageHistory from "./tabs/History";
import ApplicationPageNewRecord from "./tabs/NewRecord";
import {
	ApplicationPageTabs,
	BackofficeApplicationPageParams,
	TabContent
} from "./types";

const getSegmentsList = (
	tabs: Record<ApplicationPageTabs, TabContent>
): TabContent[] => {
	return Object.values(tabs);
};

export default function BackofficeApplicationPage(): JSX.Element {
	const navigate = useNavigate();
	const location = useLocation();
	const { applicationId, selectedTab } = useParams<
		keyof BackofficeApplicationPageParams
	>() as BackofficeApplicationPageParams;

	const setSelectedTab = useCallback(
		(newSelectedTab: ApplicationPageTabs) => {
			navigate(`/backoffice/solicitacao/${applicationId}/${newSelectedTab}`);
		},
		[navigate]
	);

	useEffect(() => {
		if (!Object.values(ApplicationPageTabs).includes(selectedTab)) {
			setSelectedTab(ApplicationPageTabs.DETAILS);
		}
	}, [selectedTab, setSelectedTab]);

	const internalUser = useUserToken();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [application, setApplication] = useState<Application>();
	const applicationService = useMemo(ApplicationService.getInstance, []);
	const [confirmResponsibleModalOpen, setConfirmResponsibleModalOpen] =
		useState(false);
	const [isLoadingSetResponsible, setIsLoadingSetResponsible] = useState(false);

	const loadApplicationDetails = useCallback(() => {
		setIsLoading(true);
		applicationService
			.getApplicationDetails(applicationId)
			.then((responseData) => {
				setApplication(responseData);
			})
			.catch((error) => toast.error(ErrorHelper.getResponseErrorMessage(error)))
			.finally(() => setIsLoading(false));
	}, []);

	const isCurrentUserResponsible = useMemo(
		() => application?.isCurrentUserResponsible(internalUser?.email),
		[application, internalUser]
	);
	const isCurrentUserInResponsibleGroup = useMemo(
		() =>
			!!(
				internalUser?.authorities &&
				application &&
				application.isCurrentUserInResponsibleGroup(internalUser?.authorities)
			),
		[internalUser?.authorities, application]
	);
	const userCanUpdateApplication = useMemo(
		() => internalUser?.hasPermission(UserAuthorities.UPDATE),
		[internalUser]
	);

	useEffect(() => {
		if (!isLoading) {
			loadApplicationDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadApplicationDetails]);

	const applicationTabs: Record<ApplicationPageTabs, TabContent> = useMemo(
		() => ({
			[ApplicationPageTabs.DETAILS]: {
				id: ApplicationPageTabs.DETAILS,
				onClick: () => setSelectedTab(ApplicationPageTabs.DETAILS),
				label: "Solicitação",
				component: ApplicationPageDetails
			},
			[ApplicationPageTabs.DOCUMENTS]: {
				id: ApplicationPageTabs.DOCUMENTS,
				onClick: () => setSelectedTab(ApplicationPageTabs.DOCUMENTS),
				label: "Documentos",
				component: ApplicationPageDocuments
			},
			[ApplicationPageTabs.HISTORY]: {
				id: ApplicationPageTabs.HISTORY,
				onClick: () => setSelectedTab(ApplicationPageTabs.HISTORY),
				label: "Histórico",
				component: ApplicationPageHistory
			},
			[ApplicationPageTabs.NEW_RECORD]: {
				id: ApplicationPageTabs.NEW_RECORD,
				onClick: () => setSelectedTab(ApplicationPageTabs.NEW_RECORD),
				label: "Novo registro",
				component: ApplicationPageNewRecord,
				disabled:
					!isCurrentUserResponsible ||
					application?.isInBOReadOnlyStatus() ||
					!userCanUpdateApplication,
				tooltip:
					!application?.hasUserResponsible() &&
					!application?.isInBOReadOnlyStatus() &&
					userCanUpdateApplication
						? "Você precisa tornar-se responsável para realizar um registro."
						: null
			}
		}),
		[application, isCurrentUserResponsible]
	);

	const currentTabContent: TabContent = useMemo(
		() => applicationTabs[selectedTab],
		[selectedTab]
	);
	const segments = useMemo(
		() => getSegmentsList(applicationTabs),
		[applicationTabs]
	);

	const reloadApplication = useCallback(() => {
		setApplication(undefined);
		loadApplicationDetails();
	}, [loadApplicationDetails, setApplication]);

	const onBecomeResponsibleUser = useCallback(() => {
		if (!application) return;
		setIsLoadingSetResponsible(true);
		const becomeResponsibleData = {
			event: {
				type: ApplicationEventType.ASSUMIR_RESPONSABILIDADE
			}
		};
		applicationService
			.registerEvent(application.id, becomeResponsibleData)
			.then(() => {
				toast.success("Agora você é responsável por esse processo");
				setConfirmResponsibleModalOpen(false);
				reloadApplication();
			})
			.catch((error) => toast.error(ErrorHelper.getResponseErrorMessage(error)))
			.finally(() => setIsLoadingSetResponsible(false));
	}, [application, applicationService, loadApplicationDetails]);

	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex flex-col md:py-6 md:px-6 py-4 px-3 flex-1 md:max-h-[100%]">
				<div className="flex flex-row my-1">
					<SegmentedButton segments={segments} selected={selectedTab} />
				</div>
				<div className="flex flex-col md:flex-row justify-between mb-2 mt-8">
					<div className="flex flex-row items-center">
						<BackButton
							prevRoute={location.state?.prevRoute ?? "/backoffice"}
						/>
						<h1 className="ml-2 font-semibold md:text-xl flex items-center">
							{currentTabContent?.label}: Processo
							{application && ` - ${application.applicationProcessCode}`}
						</h1>
					</div>
					{userCanUpdateApplication && (
						<ResponsibleUserButton
							hasResponsibleUser={application?.hasUserResponsible()}
							isCurrentUserResponsible={isCurrentUserResponsible}
							isApplicationInBOReadOnlyStatus={
								application?.isInBOReadOnlyStatus() ||
								!isCurrentUserInResponsibleGroup
							}
							responsibleUserName={NameHelper.abbreviateName(
								application?.currentResponsibleUserName ?? ""
							)}
							onClick={() => setConfirmResponsibleModalOpen(true)}
						/>
					)}
				</div>
				{currentTabContent && (
					<currentTabContent.component
						application={application}
						reloadApplication={reloadApplication}
					/>
				)}
				<ConfirmResponsibleModal
					isOpen={confirmResponsibleModalOpen}
					onClose={() => setConfirmResponsibleModalOpen(false)}
					onClickConfirm={onBecomeResponsibleUser}
					isLoadingConfirm={isLoadingSetResponsible}
				/>
			</div>
		</div>
	);
}
